import { Outlet } from "react-router-dom";
import { OutletContextProvider } from "./OutletContext";

import { Container } from "@mui/material";

const App = () => {
    return (
        <OutletContextProvider>
            <Container sx={
                {
                    maxWidth: '768px !important'
                }
            }>
                <Outlet></Outlet>
            </Container>
        </OutletContextProvider>
    )
}

export default App;