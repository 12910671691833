import React, { createContext, useContext, ReactNode } from 'react';

interface OutletContextProps {
    children: ReactNode;
}

const OutletContext = createContext<string>("");
export const OutletContextProvider: React.FC<OutletContextProps> = ({ children }) => {
    return (
        <OutletContext.Provider value={""}>
            {children}
        </OutletContext.Provider>
    );
};

export const useOutletContext = () => useContext(OutletContext);