import { FormEvent, useState, useEffect, useRef } from "react";
import { Box, Paper, Grid, FormControl, FormLabel, Typography, OutlinedInput, CircularProgress, Button } from "@mui/material";
import { io } from 'socket.io-client';
import axios from "axios";

import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Grid3x3Icon from '@mui/icons-material/Grid3x3';
import Swal from "sweetalert2";

const socket = io(`${process.env.REACT_APP_API_URL}`);

const formatDate = (date: Dayjs | null) => {
    if (!date) return '';
    return date.format('DD/MM/YYYY');
};

const GenDevice = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [valueDob, setValueDob] = useState<Dayjs | null>(null);
    const [updateMessage, setUpdateMessage] = useState<string>("");
    const [deviceId, setDeviceId] = useState<string | null>(null);

    const cardIdRef = useRef<HTMLInputElement>(null);
    const phoneNumberRef = useRef<HTMLInputElement>(null);
    const pinRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        socket.on('message', (data) => {
            if(data.type == 'update')
            {
                if(localStorage.getItem('api_auth') === data.api_auth)
                {
                    setUpdateMessage(data.message);
                }
            }
        });

        return () => {
            socket.off('message');
        };
    }, []);

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        if(loading)
        {
            return;
        }
        else if(!cardIdRef || !phoneNumberRef || !pinRef || !cardIdRef.current || !phoneNumberRef.current || !pinRef.current || !valueDob)
        {
            return Swal.fire({
                icon: 'warning',
                title: 'แจ้งเตือน',
                text: 'กรุณากรอกข้อมูลให้ครบทุกช่อง'
            });
        }

        setLoading(true);
        try {
            const cardId = cardIdRef.current.value.trim();
            const phone = phoneNumberRef.current.value.trim();
            const pin = pinRef.current.value.trim();

            setUpdateMessage("กำลังร้องขอ OTP");
            const requestOtp = await axios.post(`${process.env.REACT_APP_API_URL}/api/verifyUser`, {
                phone: phone,
                dob: formatDate(valueDob),
                cardId: cardId
            }, {});

            if(requestOtp.data.code === 200)
            {
                localStorage.setItem('api_auth', requestOtp.data.data.api_auth);
                setUpdateMessage("กำลังรอยืนยัน OTP");

                Swal.fire({
                    title: `OTP (REF: ${requestOtp.data.data.pac})`,
                    input: "text",
                    inputAttributes: {
                        autocapitalize: "off",
                    },
                    showCancelButton: true,
                    confirmButtonText: "ยืนยัน OTP",
                    cancelButtonText: "ยกเลิก"
                }).then(async (res) => {
                    if(res.isConfirmed)
                    {
                        setUpdateMessage("กำลังยืนยัน OTP");
                        const otp = res.value;
                        const submitOtp = await axios.post(`${process.env.REACT_APP_API_URL}/api/submitOtp`, {
                            api_auth: requestOtp.data.data.api_auth,
                            token_uuid: requestOtp.data.data.tokenUUID,
                            otp: otp,
                            phone: phone,
                            pin: pin
                        }, {});

                        if(submitOtp.data.code === 200)
                        {
                            setDeviceId(submitOtp.data.deviceId);
                            Swal.fire({
                                icon: 'success',
                                title: 'แจ้งเตือน',
                                html: `Gen device สำเร็จ<br/>Device ID: ${submitOtp.data.deviceId}`
                            });
                            setUpdateMessage("");
                        }
                        else
                        {
                            Swal.fire({
                                icon: 'success',
                                title: 'แจ้งเตือน',
                                html: `Gen device ไม่สำเร็จ<br/>ERROR: ${submitOtp.data.message}`
                            });
                            setUpdateMessage("");
                        }
                    }
                    else
                    {
                        setUpdateMessage("");
                    }
                });
            }
            else
            {
                Swal.fire({
                    icon: 'success',
                    title: 'แจ้งเตือน',
                    html: `Gen device ไม่สำเร็จ<br/>ERROR: ${requestOtp.data.message}`
                })
                setUpdateMessage("");
            }
        } catch (err) {
            console.error(err);
        }
        setLoading(false);
    }

    return (
        <Box component={Paper} p={3} mt={5}>
            <Box sx={{
                display: updateMessage !== null && updateMessage !== "" ? "block" : "none",
                backgroundColor: 'rgba(70, 37, 116, .8)',
                p: 2,
                borderRadius: 3,
                mb: 2,
                color: 'white'
            }}>
                <Typography display={`flex`} alignContent={`center`} alignItems={`center`}>
                    <CircularProgress size={16} sx={
                        {
                            color: 'white'
                        }
                    }/>&nbsp;กำลังดำเนินการ: {updateMessage}
                </Typography>
            </Box>
            <Box sx={{
                display: deviceId ? "block" : "none",
                backgroundColor: 'rgba(70, 37, 116, .8)',
                p: 2,
                borderRadius: 3,
                mb: 2,
                color: 'white'
            }}>
                <Typography display={`flex`} alignContent={`center`} alignItems={`center`}>
                    <Grid3x3Icon />&nbsp;Device ID: {deviceId}
                </Typography>
            </Box>
            <form name="genDeviceForm" id="genDeviceForm" onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <FormLabel htmlFor="cardId">
                                <Typography fontSize={16}>
                                    เลขบัตรประชาชน 13 หลัก
                                </Typography>
                            </FormLabel>
                            <OutlinedInput
                                name='cardId'
                                type="text"
                                size='small'
                                fullWidth
                                autoComplete="cardId"
                                inputRef={cardIdRef}
                                sx={
                                    {
                                        backgroundColor: 'white'
                                    }
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <FormLabel htmlFor="cardId">
                                <Typography fontSize={16}>
                                    วันเดือนปีเกิด
                                </Typography>
                            </FormLabel>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    value={valueDob}
                                    onChange={(newValue) => setValueDob(newValue)}
                                    sx={
                                        {
                                            backgroundColor: 'white',
                                            width: '100%',
                                            '& .MuiInputBase-root': {
                                                height: '42px', // ความสูงของ Input Base
                                                fontSize: 14,
                                            },
                                            '& .MuiPickersPopper-container': {
                                                maxHeight: '42px', // ความสูงสูงสุดของ Popper
                                            },
                                        }
                                    }
                                    slotProps={{
                                        field: { clearable: true },
                                        popper: {
                                            disablePortal: true,
                                        },
                                    }}
                                    format="DD/MM/YYYY"
                                    maxDate={dayjs()}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <FormLabel htmlFor="phoneNumber">
                                <Typography fontSize={16}>
                                    เบอร์โทรศัพท์
                                </Typography>
                            </FormLabel>
                            <OutlinedInput
                                name='phoneNumber'
                                type="tel"
                                size='small'
                                fullWidth
                                autoComplete="phoneNumber"
                                inputRef={phoneNumberRef}
                                sx={
                                    {
                                        backgroundColor: 'white'
                                    }
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <FormLabel htmlFor="pin">
                                <Typography fontSize={16}>
                                    PIN
                                </Typography>
                            </FormLabel>
                            <OutlinedInput
                                name='pin'
                                type="text"
                                size='small'
                                fullWidth
                                autoComplete="pin"
                                inputRef={pinRef}
                                sx={
                                    {
                                        backgroundColor: 'white'
                                    }
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit" fullWidth sx={
                            {
                                backgroundColor: '#4e2a82',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: '#2f194e',
                                    color: 'white',
                                }
                            }
                        } disabled={loading}>
                            <Typography fontSize={16} fontWeight={600}>
                                {
                                    loading ? <CircularProgress size={24} sx={
                                        {
                                            color: 'white'
                                        }
                                    } /> : "Gen device"
                                }
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    )
}

export default GenDevice;